@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// local var
$field_vertical_gutter: 40px;

// Custom steppers contents
.v-stepper {
  // Custom controls container
  .step-footer-actions {
    display: flex;
    margin-top: $field_vertical_gutter;

    &.col {
      padding-top: 0;
      padding-bottom: 0;
    }

    .v-btn {
      margin-left: 0;
      margin-right: var(--box-padding-admin);
    }
  }
}

.time-setting-fieldset {
  .time-block-item {
    margin-top: 0;
    margin-bottom: $field_vertical_gutter;

    ::v-deep .legend-header {
      margin-top: 0;
    }
  }
}

::v-deep .form-footer-actions {
  margin-top: var(--field-vertical-gutter);
}

.form-summary {
  &__heading {
    font-weight: 400;
    font-size: var(--font-size-sm);
    color: var(--color-text-subtle-theme);
    opacity: 0.7;
  }
  &__content {
    .time {
      white-space: nowrap;

      // fix wx-list-inline-items.text-right
      @media ($wx-sm-min) {
        &::after {
          padding-right: 0;
        }
      }
    }
  }
}
