@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-checkbox-weekdays {
  position: relative;

  .checkboxes-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .v-input--selection-controls {
      &.v-input--checkbox {
        max-width: none;
        margin-top: 0.5rem;
        margin-right: 1rem;

        ::v-deep .v-label {
          text-transform: capitalize;
        }

        // Responsive
        @media ($wx-sm-min) {
          margin-right: 0;
        }
      }
    }
  }

  // prop: show-days-first-character = Enabled
  &.first-characters {
    .checkboxes-label {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      padding-bottom: 0.4rem;
      white-space: nowrap;
    }

    @media ($wx-lg-min) {
      .checkboxes-label {
        margin-right: 2rem;
      }
    }

    @media ($wx-sm-min) {
      display: flex;
      flex-direction: row;

      .checkboxes-wrapper {
        justify-content: space-between;

        ::v-deep .v-input--selection-controls {
          margin-top: 0;
        }
        .v-input--selection-controls {
          &.v-input--checkbox {
            ::v-deep .v-input__control {
              position: relative;
              margin-top: 0;
              margin-right: 0;

              .v-messages {
                display: none;
              }
              .v-input--selection-controls__input {
                position: absolute;
                opacity: 0;
              }
            }

            // round labels
            ::v-deep .v-label {
              justify-content: center;
              min-width: 1.4rem;
              margin-inline: 0; // over ruled by `justify-content: space-between`
              padding: 0.2rem 0.6rem;
              font-size: var(--font-size-sm);
              background-color: var(--color-secondary);
              border: 1px solid var(--color-secondary);
              border-radius: 50%;

              // Use JS to wrapp other then first letters :  M<span.other-letters>onday</span>
              .other-letters {
                display: none;
              }

              &.error--text {
                border-color: var(--color-error);
              }
              &.warning--text {
                border-color: var(--color-warning);
              }
              &.success--text {
                border-color: var(--color-success);
              }
            }

            // checked round labels
            &.v-input--is-label-active {
              ::v-deep .v-label {
                background-color: var(--color-primary);
              }
            }

            // larger then a vertical phone
            @media (min-width: $wx-min-width-phone-vertical) {
              ::v-deep .v-label {
                min-width: 2.5rem;
                margin-inline: 0.2rem;
                padding: 0.6rem;
                font-size: var(--font-size-norm);
              }
            }
          }
        }
      }
    }
  }
}
